// Generated by Framer (f4ba5e0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["mlbLEZ1oz", "zix4GDLjl"];

const serializationHash = "framer-EzYNt"

const variantClassNames = {mlbLEZ1oz: "framer-v-1kl03m3", zix4GDLjl: "framer-v-46pt33"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {celular: "mlbLEZ1oz", Computador: "zix4GDLjl"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mlbLEZ1oz"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mlbLEZ1oz", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1kl03m3", className, classNames)} data-framer-name={"celular"} layoutDependency={layoutDependency} layoutId={"mlbLEZ1oz"} ref={refBinding} style={{...style}} {...addPropertyOverrides({zix4GDLjl: {"data-framer-name": "Computador"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "23px", "--framer-font-weight": "700", "--framer-line-height": "34.5px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 162, 64))", "--framer-text-transform": "uppercase"}}>DE R$ 197,00 POR R$ 47,00</motion.p></React.Fragment>} className={"framer-196c52p"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"ogBGpV4Vl"} style={{"--extracted-r6o4lv": "rgb(255, 162, 64)"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({zix4GDLjl: {children: <React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "24px", "--framer-font-weight": "700", "--framer-line-height": "34.5px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 162, 64))", "--framer-text-transform": "uppercase"}}>DE R$ 197,00 POR R$ 47,00</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/><motion.div className={"framer-14ziobp"} layoutDependency={layoutDependency} layoutId={"elfFN0Ifq"} style={{backgroundColor: "rgb(255, 173, 87)", rotate: 90}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-EzYNt.framer-pg8azs, .framer-EzYNt .framer-pg8azs { display: block; }", ".framer-EzYNt.framer-1kl03m3 { height: 34px; overflow: hidden; position: relative; width: 328px; }", ".framer-EzYNt .framer-196c52p { -webkit-user-select: none; flex: none; height: auto; left: 50%; pointer-events: auto; position: absolute; top: 50%; user-select: none; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", ".framer-EzYNt .framer-14ziobp { flex: none; height: 116px; left: 90px; overflow: hidden; position: absolute; top: -41px; width: 2px; z-index: 1; }", ".framer-EzYNt.framer-v-46pt33.framer-1kl03m3 { aspect-ratio: 9.647058823529411 / 1; height: var(--framer-aspect-ratio-supported, 35px); width: 337px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 328
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zix4GDLjl":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerwAt18oNo7: React.ComponentType<Props> = withCSS(Component, css, "framer-EzYNt") as typeof Component;
export default FramerwAt18oNo7;

FramerwAt18oNo7.displayName = "De-Por-Hero";

FramerwAt18oNo7.defaultProps = {height: 34, width: 328};

addPropertyControls(FramerwAt18oNo7, {variant: {options: ["mlbLEZ1oz", "zix4GDLjl"], optionTitles: ["celular", "Computador"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerwAt18oNo7, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/DpPBYI0sL4fYLgAkX8KXOPVt7c.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/4RAEQdEOrcnDkhHiiCbJOw92Lk.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/1K3W8DizY3v4emK8Mb08YHxTbs.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/tUSCtfYVM1I1IchuyCwz9gDdQ.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/VgYFWiwsAC5OYxAycRXXvhze58.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/DXD0Q7LSl7HEvDzucnyLnGBHM.woff2", weight: "700"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/GIryZETIX4IFypco5pYZONKhJIo.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})